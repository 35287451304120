import React, { useState, useRef } from "react";
import { PermissionsTunnel } from "iframe-permissions-tunnel";
import { Dimensions } from "react-native";

export const PlayvisitEmbed = ({ link , idioma }) => {
  const iframeRef = useRef(null);
  const [isShowingPrompt, setIsRequestingPermissions] = useState(false);

const onIframeLoad = () => {

  async function setupTunnel() {
    PermissionsTunnel.forwardTo(iframeRef.current.contentWindow);

    PermissionsTunnel.onPermissionPromptShown(() =>
      setIsRequestingPermissions(true)
    );
    PermissionsTunnel.onPermissionPromptHidden(() =>
      setIsRequestingPermissions(false)
    );
     PermissionsTunnel.registerCallback("playvisit/session-created",({gameId, gameToken}) => {
        console.log("entra?"+gameToken);
        localStorage.setItem("gameToken_"+gameId, gameToken);
      });
      

      PermissionsTunnel.registerCallback("playvisit/poi-update", msg => {
        console.log("game_"+msg.gameId);
        //console.log("Prova=>" + idioma);
        msg.idioma = idioma;
        console.log(msg);
        let aux =  localStorage.getItem('Gamelist');
        var Gamelist = aux  
          if(Gamelist !== null){
            Gamelist = Gamelist.toString();
            if(!Gamelist.includes(""+msg.gameId)){
              localStorage.setItem('Gamelist',Gamelist +","+ msg.gameId);
            }
          }else{
            localStorage.setItem('Gamelist',msg.gameId);
          }
          getPoint(msg)
          
      });        
  }
  setupTunnel();
};

async function getPoint(msg){
  
  let data = await localStorage.getItem('Game'+msg.gameId); 
  if(data != null){
    let gameId = JSON.parse(data);
    console.log(gameId);
    if(gameId !== null && gameId != undefined){
      console.log("entra?");
      console.log(msg.passedPoisIds.length);
      console.log(gameId.passedPoisIds.length);
      if( gameId.passedPoisIds.length <= msg.passedPoisIds.length){
        localStorage.setItem('Game'+msg.gameId,JSON.stringify(msg));
        console.log("Ha entrat");
      }else{
        console.log("Error game");
      }
    } 
  }else{
    console.log("entra null");
    localStorage.setItem('Game'+msg.gameId,JSON.stringify(msg));
  }

}

  return (
    <iframe
      ref={iframeRef}
      onLoad={onIframeLoad}
      height={(Dimensions.get('window').height/100)*90}
      width="99.9%"
      src={link}
      allow="fullscreen;geolocation;camera;gyroscope;accelerometer;magnetometer"
    />
  );
};

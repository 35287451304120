import React from 'react';
import { View, Text, ImageBackground, Image, TouchableOpacity, ScrollView, Dimensions} from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import style from './style';
import api from '../config/api_urls';
import Menu from '../menu';
import { Ionicons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-community/async-storage';

//ordre 4
 class Home extends React.Component{



    constructor(props) {
        super(props);
        this.state = props.route.params;
        this.state['navigation'] = this.props.navigation;

        this.pages = (typeof this.state.projecte !== 'undefined' && typeof this.state.projecte.pagines !== 'undefined') ? this.state.projecte.pagines : [];
        this.state["selectedPage"] = {}
     //   console.log(this.state)
    }

    loadPagina(tipus, props) {
      this.state["selectedPage"] = props;
      if(tipus == "Tipus1") {
        AsyncStorage.setItem('anarCredits',true);
        this.props.navigation.navigate('Joc1', this.state)
      }
      else if (tipus == "Tipus2") {
         AsyncStorage.setItem('anarCredits',false);
        this.props.navigation.navigate('Joc2', this.state)
      }
    }

    render() {
      const items = []
      if(this.pages.length > 0) {
        for (const [index, value] of this.pages.entries()) {
          if(value.Tipus != "Tipus3"){
          items.push(
            <View style={[this.state.projecte.MenuAmbCaixeti ? style.CaixetiLlistaVertical : style.CaixetiLlistaHoritzontal,{backgroundColor: value.ColorPagina}]}>
              <TouchableOpacity key={index}  onPress={()=>{ this.loadPagina(value.Tipus, value); }}>
                {this.state.projecte.MenuAmbCaixeti ? 
                  (
                  <View>
                    <Image source={{ uri: api.api_url + value.Imatge.url}} style={{height: this.state.pop_up_height * 0.17, resizeMode: 'contain', marginTop: 1}}></Image>
                    <Text style={[(this.pages.length-1) === index ? style.textUltim : style.text, {color:this.state.projecte.ColorCorporatiuText1}]}>{value['Nom'+this.state.idioma]} </Text> 
                  </View>
                  )
                :
                  <Text style={{paddingLeft:3 , fontSize:18 , fontWeight:'bold'}}>
                  <Ionicons name="ios-play" size={17} color="red"  /> {"  "}
                    { value['Nom'+this.state.idioma] ? value['Nom'+this.state.idioma].toUpperCase() : null}
                  </Text>
                }
              </TouchableOpacity>
            </View>
          )
        }
      }
      }
      
      if(this.state.projecte.MenuAmbCaixeti){
        //MET DE RIBES 
        return(
          <View style={{ flex: 1 }}>
          <View style={{ flex: 15 }}>
            <ImageBackground source={{ uri: api.api_url + this.state.projecte.FonsPantalla3.url }} style={style.imageBackgroundVertical}>
              <View style={ this.state.projecte.MidaMaximaPagines ? null :{marginTop: this.state.pop_up_margin_top , maxHeight: this.state.pop_up_height,paddingBottom: Dimensions.get('screen').width * 0.15}}>
                    <ScrollView>
                      {items}
                    </ScrollView>
                  </View>
            </ImageBackground>
          </View>
          <View style={{ flex: 2, bottom:0,left:0,right:0, position:'fixed' }}>
            <Menu projecte={this.state}></Menu>
          </View>
        </View>
          )
      }else{
        return(
          <View style={{ flex: 1 }}>
          <View style={{ flex: 15 }}>
            <ImageBackground source={{ uri: api.api_url + this.state.projecte.FonsPantalla3.url }} style={style.imageBackgroundHoritzontal}>
              <View style={ this.state.projecte.MidaMaximaPagines ? null :{marginTop: this.state.pop_up_margin_top , maxHeight: this.state.pop_up_height,paddingBottom: Dimensions.get('screen').width * 0.15}}>
                <View style={style.caixaOut}>
                  <View style={style.caixa}>
                    <ScrollView style={style.caixa}> 
                        {items}
                    </ScrollView>
                  </View>
                </View>
              </View>
            </ImageBackground>
          </View>
          <View style={{ flex: 2, bottom:0,left:0,right:0, position:'fixed' }}>
            <Menu projecte={this.state}></Menu>
          </View>
        </View>
          )
      }
     
    }
}
export default Home;

/*

for (const [index, value] of this.pages.entries()) {
          items.push(
            <View style={{flex:1 , backgroundColor: value.ColorPagina, paddingTop:7}}>
            <TouchableOpacity key={index}  onPress={()=>{ this.loadPagina(value.Tipus, value); }}>
              <Image source={{ uri: api.api_url + value.Imatge.url}} style={{height: this.state.pop_up_height * 0.17, resizeMode: 'contain', marginTop: 1}}></Image>
              <Text style={[(this.pages.length-1) === index ? style.textUltim : style.text, {color:this.state.projecte.ColorCorporatiuText1}]}>{value['Nom'+this.state.idioma]} </Text>
            </TouchableOpacity>
            </View>
          )
        }
*/
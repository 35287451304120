import React from 'react';
import { View, Text, ImageBackground, Image, TouchableOpacity,TextInput } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import style from './style';
import api from '../config/api_urls';
 import { setCurrentScreen } from 'expo-firebase-analytics';
 import { setAnalyticsCollectionEnabled } from 'expo-firebase-analytics';



//ordre 3
export default class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = props.route.params;
    this.state["nom"] = "";
    this.state["boto"] = "";
  }

  componentDidMount(){
    this.carregarTraducio()
  }

  carregarTraducio(){
  //  console.log(this.state.idioma);
    switch(this.state.idioma){
      case '_ES':
        this.setState({boto: this.state.projecte['IniciarSessioLogin'+this.state.idioma] })
        this.setState({placeholder :this.state.projecte['NomLogin'+this.state.idioma]})
        break;
      case '_FR':
        this.setState({boto :this.state.projecte['IniciarSessioLogin'+this.state.idioma]})
        this.setState({placeholder :this.state.projecte['NomLogin'+this.state.idioma]})
        break;
      case '_EN':
        this.setState({boto :this.state.projecte['IniciarSessioLogin'+this.state.idioma]})
        this.setState({placeholder :this.state.projecte['NomLogin'+this.state.idioma]})
        break;
      default:
        this.setState({boto :this.state.projecte['IniciarSessioLogin'+this.state.idioma]})
        this.setState({placeholder :this.state.projecte['NomLogin'+this.state.idioma]})
        break;
    }
  }

 async send() {
    if (this.state.nom != null && this.state.nom != '') {
       await setCurrentScreen("HaFetLogin")
       await setAnalyticsCollectionEnabled(true)
      AsyncStorage.setItem('nom', this.state.nom);
      this.props.navigation.navigate('Home', this.state);
    }
  }
//<Image style={{ height: this.state.pop_up_height * 0.7, resizeMode: 'contain' }} source={{ uri: api.api_url + this.state.projecte.ImatgeLogin.url }} />
  render() {
    return (
      <View style={{ flex: 1 }}>
        <ImageBackground source={{ uri: api.api_url + this.state.projecte.FonsPantalla2.url }} style={style.imageBackground}>
          <View style={{ marginTop: this.state.pop_up_margin_top * 1.2, height: (this.state.pop_up_height * 1) }}>
            <View style={[style.caixaOut, {backgroundColor: this.state.projecte.colorPrincipal2}]}>
              <View style={[style.caixa,this.state.projecte.BorderContenidor ? style.caixaBorderContenidor : null , { borderColor: this.state.projecte.ColorBorderContenidor} ]}>
                <View style={{ alignContent: 'center' }}>
                  <Image style={{ height: this.state.pop_up_height * 0.7, resizeMode: 'contain' }} source={{ uri: api.api_url + this.state.projecte.ImatgeLogin.url }} />
                </View>
                <View style={{ alignContent: 'center', paddingTop: 10 }}>
                  <TextInput
                    autoCorrect={false}
                    placeholder={this.state.placeholder}
                    placeholderTextColor={style.colorCorporatiu}
                    //underlineColorAndroid="transparent"
                    returnKeyType="send"
                    onChangeText={text => this.setState({ nom: text })}
                    style={style.textNom}
                    value={this.state.nom}
                  />
                  <TouchableOpacity onPress={() => { this.send() }} >
                    <Text style={[style.textLogin,{color:this.state.projecte.ColorCorporatiuText2}]}>{this.state.boto}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </ImageBackground>
      </View>
    )
  }
}

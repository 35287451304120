import React, { Component } from 'react';
import { StyleSheet, Text, View, Image, Alert, TouchableOpacity,Platform } from 'react-native';
import api from './config/api_urls';
import style from './menu/style';


export default class Menu extends React.Component{
    constructor(props) {
        super(props);
        this.state = this.props.projecte;
   //     console.log(this.state)
    }
/*
    _handlePressButtonAsync = async () => {
        let result = await WebBrowser.openBrowserAsync('https://studio.playvisit.com/app/1547');
        console.log(result);
      };
*/     



render(){
    if(this.state.projecte.BotoMenu5 && this.state.projecte.BotoMenu6){ // nomes 6
        return(

            <View style={[style.container,{backgroundColor: this.state.projecte.ColorBarraMenu}]}>
                <TouchableOpacity style={[style.menu6]} onPress={()=>{this.state.navigation.navigate('Home',this.state)}}>  
                    <Image style={{width:60,height:60,alignSelf:'center'}} source={{ uri: api.api_url + this.state.projecte.IconaMenuHome.url}} />
                </TouchableOpacity>
                <TouchableOpacity style={style.menu6} onPress={()=>{ this.state.navigation.navigate('Rondalla',this.state)}}>  
                    <Image style={{width:60,height:60,alignSelf:'center'}} source={{ uri: api.api_url + this.state.projecte.BotoMenu5Icona.url}} />
                </TouchableOpacity>
                <TouchableOpacity style={[style.menu6]} onPress={()=>{ this.state.navigation.navigate('Pagina6',this.state
                ) }}>  
                    <Image style={{width:60,height:60,alignSelf:'center'}} source={{ uri: api.api_url + this.state.projecte.BotoMenu6Icona.url}} />
                </TouchableOpacity>
                <TouchableOpacity style={style.menu6} onPress={()=>{this.state.navigation.navigate('Joc3',this.state)}}>  
                    <Image style={{width:60,height:60,alignSelf:'center'}} source={{ uri: api.api_url + this.state.projecte.IconaMenuVR.url}} />
                </TouchableOpacity>
                <TouchableOpacity style={[style.menu6]} onPress={()=>{this.state.navigation.navigate('Credits',this.state)}}>  
                    <Image style={{width:60,height:60,alignSelf:'center'}} source={{ uri: api.api_url + this.state.projecte.IconaMenuCredits.url}} />
                </TouchableOpacity>
                <TouchableOpacity style={style.menu6} onPress={()=>{ this.state.navigation.navigate('Perfil', this.state)}}>  
                    <Image style={{width:60,height:60,alignSelf:'center'}} source={{ uri: api.api_url + this.state.projecte.IconaMenuPerfil.url}} />
                </TouchableOpacity>
            </View>
            )
    }else if(this.state.projecte.BotoMenu5 || this.state.projecte.BotoMenu6){ // nomes 5
        return(
        <View style={[style.container ,{backgroundColor: this.state.projecte.ColorBarraMenu}]}>
            <TouchableOpacity style={[style.menu5]} onPress={()=>{this.state.navigation.navigate('Home',this.state)}}>  
                    <Image style={{width:60,height:60,alignSelf:'center'}} source={{ uri: api.api_url + this.state.projecte.IconaMenuHome.url}} />
                </TouchableOpacity>
                { this.state.projecte.BotoMenu5 ? (
            <TouchableOpacity style={[ style.menu5]} onPress={()=>{this.state.navigation.navigate('Rondalla', this.state)}}>  
                <Image style={{width:60,height:60,alignSelf:'center'}} source={{ uri: api.api_url + this.state.projecte.BotoMenu5Icona.url}}  />
            </TouchableOpacity> ):(
            <TouchableOpacity style={[ style.menu5]} onPress={()=>{this.state.navigation.navigate('Rondalla', this.state)}}>  
                <Image style={{width:60,height:60,alignSelf:'center'}} source={{ uri: api.api_url + this.state.projecte.BotoMenu6Icona.url}}  />
            </TouchableOpacity>
            )}
                <TouchableOpacity style={[ style.menu5]} onPress={()=>{this.state.navigation.navigate('Joc3',this.state)}}>  
                    <Image style={{width:60,height:60,alignSelf:'center'}} source={{ uri: api.api_url + this.state.projecte.IconaMenuVR.url}} />
                </TouchableOpacity>
                <TouchableOpacity style={[ style.menu5]} onPress={()=>{this.state.navigation.navigate('Credits',this.state)}}>  
                    <Image style={{width:60,height:60,alignSelf:'center'}} source={{ uri: api.api_url + this.state.projecte.IconaMenuCredits.url}} />
                </TouchableOpacity>
                <TouchableOpacity style={[ style.menu5]} onPress={()=>{ this.state.navigation.navigate('Perfil', this.state)}}>  
                    <Image style={{width:60,height:60,alignSelf:'center'}} source={{ uri: api.api_url + this.state.projecte.IconaMenuPerfil.url}} />
                </TouchableOpacity>
        </View>
            )    
    }else {
        return( // nomes 4
            <View style={[style.container,{backgroundColor: this.state.projecte.ColorBarraMenu}]}>
                 <TouchableOpacity style={[style.menu4]} onPress={()=>{this.state.navigation.navigate('Home',this.state)}}>  
                    <Image style={{width:60,height:60,alignSelf:'center'}} source={{ uri: api.api_url + this.state.projecte.IconaMenuHome.url}} />
                </TouchableOpacity>
                <TouchableOpacity style={[style.menu4]} onPress={()=>{this.state.navigation.navigate('Joc3',this.state)}}>  
                    <Image style={{width:60,height:60,alignSelf:'center'}} source={{ uri: api.api_url + this.state.projecte.IconaMenuVR.url}} />
                </TouchableOpacity>
                <TouchableOpacity style={[style.menu4]} onPress={()=>{this.state.navigation.navigate('Credits',this.state)}}>  
                    <Image style={{width:60,height:60,alignSelf:'center'}} source={{ uri: api.api_url + this.state.projecte.IconaMenuCredits.url}} />
                </TouchableOpacity>
                <TouchableOpacity style={[style.menu4]} onPress={()=>{ this.state.navigation.navigate('Perfil', this.state)}}>  
                    <Image style={{width:60,height:60,alignSelf:'center'}} source={{ uri: api.api_url + this.state.projecte.IconaMenuPerfil.url}} />
                </TouchableOpacity>
            </View>
            )   
    }       
}  
}

//this.state.navigation.navigate('Prova',this.state)
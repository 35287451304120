import React from 'react';
import { View, Text, ImageBackground, Image, ScrollView,Dimensions } from 'react-native';
import style from './style';
import api from '../config/api_urls';
import Menu from '../menu';
import ReactMarkdown from 'react-markdown';
import AsyncStorage from '@react-native-community/async-storage';


export default class Credits extends React.Component {
    state = {};
    constructor(props) {
        super(props);
        this.state = props.route.params;
    }
    componentDidMount(){
        //Serveix per redirecionar al menu o als creditsInici
        AsyncStorage.setItem('anarCredits',true);
    }


   render() {
        return (
            <View style={{ flex: 1 }}>
                <View style={{ flex: 15 }}>
                    <ImageBackground source={this.state.projecte.FonsPantalla2 ?{ uri: api.api_url + this.state.projecte.FonsPantalla2.url } : null} style={style.imageBackground}>
                        <View style={{marginTop: this.state.pop_up_margin_top, maxHeight: this.state.pop_up_height,paddingBottom: Dimensions.get('screen').width * 0.15 , }}>
                            <View style={[style.caixaOut, {backgroundColor: this.state.projecte.colorPrincipal2}]}>
                                <View style={[style.caixa,this.state.projecte.BorderContenidor ? style.caixaBorderContenidor : null , { borderColor: this.state.projecte.ColorBorderContenidor } ]}>
                                    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                                        <View style={{ alignItems: 'center', paddingTop: 4 }}>
                                            <Image source={this.state.projecte.ImatgeCredits ?{ uri: api.api_url + this.state.projecte.ImatgeCredits.url } : null} style={{ height: this.state.pop_up_height * 0.15, width: this.state.pop_up_height * 0.17, marginLeft: 7.5, marginRight: 7.5, flexWrap: 'wrap', alignItems: 'stretch' }} />
                                            <Text style={[style.titol,{color:this.state.projecte.ColorCorporatiuText1}]}>{this.state.projecte['TitolCredits'+this.state.idioma]}</Text>
                                        </View>
                                        <View style={{ borderColor: 'white', borderTopWidth: 1, marginHorizontal: '5%' }}>

                                        </View>
                                        <View style={{ paddingTop: 5, marginHorizontal: '5%', color: this.state.projecte.ColorCorporatiuText2, fontSize: 13, textAlign: "justify", fontFamily: 'OpenSans' }}>
                                        <ReactMarkdown  style={style.text}>{this.state.projecte['Credits'+this.state.idioma]}</ReactMarkdown>
                                        </View>
                                    </ScrollView>
                                </View>
                            </View>
                        </View>
                    </ImageBackground>
                </View>
                <View style={{ flex: 2, bottom:0,left:0,right:0, position:'fixed', }}>
                    <Menu projecte={this.state}></Menu>
                </View>
            </View>
        )
    }
}
import React from 'react';
import { View, Text, ImageBackground, Image, ScrollView, Dimensions,TouchableHighlight,TouchableWithoutFeedback,} from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import style from './style';
import Menu from '../menu';
import Modal from "modal-react-native-web";
import api from '../config/api_urls';

export default class Perfil extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.route.params;
    this.state['premis'] = [];
    this.state['imatge'] = [];
    this.state['postes'] = [];
    this.state['joc'] = "";
    this.state['gameList']=[];
    this.state['visible']= false;
    this.state['imgClick'] = 0;

    if (typeof this.state.nom === 'undefined') {
      this.state['nom'] = '';
    }
    if (typeof this.state.cookies === 'undefined') {
      this.state['cookies'] = '';
    }
  }
  
  componentDidMount() {
    this.getGameList();
    this.getNom();
    AsyncStorage.setItem('anarCredits',true);
    setTimeout(()=>{this.getGamePoints()},50)     
  }

 getGamePoints = async () =>{
   let punts = 0;
   let postes = []
   let passedPois = []
  for( let i = 0; i < this.state.gameList.length;i++){
    const value = await AsyncStorage.getItem('Game'+[this.state.gameList[i]]);
    if(value !== null){
     // console.log(JSON.parse(value).idioma);
      if(JSON.parse(value).idioma == this.state.idioma){
        punts += JSON.parse(value).score;
        postes += JSON.parse(value).gainedRewardsIds+",";
        passedPois += JSON.parse(value).passedPoisIds+",";
      }
    }
  }
  this.setState({joc:{score : punts , gainedRewardsIds : postes , passedPoisIds : passedPois}})
 }

  getGameList = async () => {
    try {
      const value = await AsyncStorage.getItem('Gamelist');
      if (value !== null) {
        this.setState({ gameList: value.split(",") });
      }
    } catch (e) {
      console.log(e);
    }
  }
  getNom = async () => {
    try {
      const value = await AsyncStorage.getItem('nom');
      if (value !== null) {
        this.setState({ nom: value });
      }
    } catch (e) {
      console.log(e);
    }
  }

 
   mostrarImatges(){
     return this.state.projecte.pagines.map((game)=>{
      if(game.Tipus == "Tipus2"){
        return(
              <ScrollView horizontal={true}>
                {this.getImatges(game.id)}
              </ScrollView>
        )
      }else{
        return( null )
      }
    })
   }

  getImatges(joc){
    return this.state.projecte.premis.map((premis,i)=>{

      if(joc == premis.game){
        return( 
          <View style={{ paddingBottom:10, paddingLeft:10, paddingTop:10}}>
            <View style={[this.state.projecte.MarcPremis ?
              {backgroundColor:this.state.projecte.ColorMarcPremis,paddingHorizontal:1,paddingTop:5,paddingBottom:15,borderRadius:10,marginHorizontal:5}
              : null]}>  
              { this.comprovar(premis['poi_id'+this.state.idioma]) ? 
                (<View style={{ alignItems:'center',}}>
                  <TouchableHighlight
                    style={style.button}
                    onPress={() => {
                      this.setModalVisible(true);
                      this.setState({imgClick : i})
                    }}
                  >
                    <Image style={style.imatgesEstarnin} source={{ uri: api.api_url + premis.Premi.url }} />
                  </TouchableHighlight>
                </View>)
              :
                (<View style={{alignItems:'center'}}>
                    <Image style={style.imatgesEstarniNoAconseguit} source={require('../../img/premiUndefined.png')} />       
                </View>)
              }
            </View>
          </View>
        )
      }
    })
  }
  
  imatges() {
    // console.log(this.state.joc);
     if(this.state.joc !== undefined && this.state.joc !== "" ){
     return this.state.projecte.premis.map((data, i) => {    
       return (
        <View style={[this.state.projecte.MarcPremis ?
        {backgroundColor:this.state.projecte.ColorMarcPremis,paddingHorizontal:1,paddingTop:5,paddingBottom:15,borderRadius:10,marginHorizontal:5}
        : null,{width:'33%', alignContent:'center'}]}>  
          { this.comprovar(data['poi_id'+this.state.idioma]) ?
         (
           <View style={{alignItems:'center'}}>
           <TouchableHighlight
             style={[style.button]}
             onPress={() => {
               this.setModalVisible(true);
               this.setState({imgClick : i})
             }}
           >
          <Image style={style.aconseguit} source={{ uri: api.api_url + data.Premi.url }} />
           </TouchableHighlight>          
             </View>
         )
         :
         this.isPassedPoi(data['poi_id'+this.state.idioma]) ?
         (
          <View style={{alignItems:'center'}}>
          <TouchableHighlight
            style={[style.button]}
            onPress={() => {
              this.setModalVisible(true);
              this.setState({imgClick : i})
            }}
          >
         <Image style={[style.noAconseguit,{opacity:0.3}]} source={{ uri: api.api_url + data.Premi.url }} />
          </TouchableHighlight>          
            </View>
        )
        :
         (
           <View style={{alignItems:'center'}}>        
              <Image style={style.noAconseguit}source={require('../../img/premiUndefined.png')} />        
            </View>
         )
       }
        <Text style={{ alignSelf: 'center', color: "white", fontWeight: 500, fontSize: 10, paddingVertical:5,pdingRight:5}}></Text> 
          </View>
         )
     })
   }
   }
   comprovar(id){
     if(this.state.joc.gainedRewardsIds != null && this.state.joc.gainedRewardsIds != undefined){
       if(this.state.joc.gainedRewardsIds.includes(id)){
         return true ;
       }else return false; 
 
     }
    return false;
   }
   isPassedPoi(id){
    // console.log("entra?");
    // console.log(this.state.joc);
    if(this.state.joc.passedPoisIds != null && this.state.joc.passedPoisIds != undefined){
      if(this.state.joc.passedPoisIds.includes(id)){
        console.log("entra true?");
        return true ;
      }else return false; 

    }
   return false;
  }
 /*********** 
  MODAL
 ***********/
setModalVisible = visible => {
  this.setState({ visible });
};


render() {
  return (
    <TouchableWithoutFeedback onPress={() => this.setState({visible: false}) }>
      <View style={[{ flex: 1 }]}>
        <View style={{ flex: 15 }}>
          <ImageBackground source={this.state.projecte.FonsPantalla7 ? { uri: api.api_url + this.state.projecte.FonsPantalla7.url } : null} style={style.imageBackground}>
            <View style={ this.state.projecte.MidaMaximaPagines ? null :{marginTop: this.state.pop_up_margin_top , maxHeight: this.state.pop_up_height,paddingBottom: Dimensions.get('screen').width * 0.15}}>
              <View style={[style.caixaOut, {backgroundColor: this.state.projecte.colorPrincipal2}]}>
                <View style={[style.caixa,this.state.projecte.BorderContenidor ? style.caixaBorderContenidor : null , { borderColor: this.state.projecte.ColorBorderContenidor} ]}>
                  <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                    <View style={{ alignItems: 'center', paddingTop: 4 ,borderBottomWidth:2, borderColor :'white'}}>
                      { this.state.projecte.ImatgePerfil != null ?
                        <Image source={{ uri: api.api_url + this.state.projecte.ImatgePerfil.url}} style={{height: this.state.pop_up_height * 0.17, width: this.state.pop_up_height * 0.17, marginLeft: 7.5, marginRight: 7.5, flexWrap: 'wrap', alignItems:'stretch'}} />
                      :
                        null
                      }
                      <Text style={[style.titol,{color:this.state.projecte.ColorCorporatiuText1}]}>{this.state.projecte['TitolPerfil'+this.state.idioma]}</Text>
                    </View>
                    <View style={{ borderColor: 'white', marginHorizontal: '5%',borderBottomWidth:2 }}>
                      <Text style={[style.nom,{color:this.state.projecte.ColorCorporatiuText1}]}>{this.state.nom}</Text>
                    </View>
                    <View style={{ borderColor: 'white',  marginHorizontal: '5%' }}>
                      <View style={{ alignSelf: 'center', paddingTop: 5 }}>
                        <Text style={[style.puntsText,{color:this.state.projecte.ColorCorporatiuText2}]}>{this.state.projecte['PuntsPerfil'+this.state.idioma]}</Text>
                        <Text style={[style.text2,{color:this.state.projecte.ColorCorporatiuText1}]}>{this.state.joc.score}</Text>
                      </View>
                      <View style={{ borderColor: 'white'}}>
                        <View style={{paddingTop: 4 }}>
                          <Text  style={[style.puntsText,{color:this.state.projecte.ColorCorporatiuText2}]}>{this.state.projecte['RecompensesPerfil'+this.state.idioma]}</Text>
                            { this.state.projecte.PremiVertical ?
                              (
                                <View style={{ flexDirection: 'row',  flexWrap: 'wrap',justifyContent:'center', paddingTop: 20, alignItems:'center' }}>
                                  {this.imatges()}
                                </View>
                              ) 
                            :
                              (
                                <ScrollView horizontal={!this.state.projecte.PremiVertical}>
                                  {this.mostrarImatges()}
                                </ScrollView> 
                              )
                            }
                        </View>
                      </View>
                    </View>
                  </ScrollView>
                </View>
              </View>
            </View>
            {this.state.visible?<View style={{position:'absolute',top:0,bottom:0,right:0,left:0 , backgroundColor:'rgba(216, 216, 216, 0.6)'}}></View> : null}
          </ImageBackground>
        </View>
      <View style={{ flex: 2, bottom:0,left:0,right:0, position:'fixed', }}>
        <Menu projecte={this.state}></Menu>
      </View>

      <Modal
        animationType="slide"
        transparent={true}
        ariaHideApp={false}
        style={{backgroundColor:'blue'}}
        onBackdropPress={() => {this.setState({ visible: false });}}
        visible={this.state.visible}
        >
        <View style={{ marginTop: 22 }}>
        <TouchableWithoutFeedback onPress={() => {}}>

        <View style={ this.state.projecte.MidaMaximaPagines ? null :{marginTop: this.state.pop_up_margin_top , maxHeight: this.state.pop_up_height,paddingBottom: Dimensions.get('screen').width * 0.1 , marginHorizontal:'10%'}}>
          <View style={style.caixaOut , {backgroundColor: this.state.projecte.colorPrincipal2}}>
            <View style={[style.caixa,this.state.projecte.BorderContenidor ? style.caixaBorderContenidor : null , { borderColor: this.state.projecte.ColorBorderContenidor} ]}>
              <View style={[style.caixa,{marginTop:30}]}> 
                <Image style={style.imatgeClick} source={this.state.projecte.premis.length != 0 ? { uri: api.api_url + this.state.projecte.premis[this.state.imgClick].Premi.url } :null} />
                <Text style={style.textImatge}>{this.state.projecte.premis.length != 0 ? this.state.projecte.premis[this.state.imgClick]['Nom'+this.state.idioma] : null}  </Text>
              </View>
            </View>
          </View>
        </View>
      </TouchableWithoutFeedback>
      </View>
      </Modal>
    </View>
  </TouchableWithoutFeedback>
  )
}
}

import React from 'react';
import { View, Text, ImageBackground, Image, TouchableOpacity, ScrollView, Dimensions} from 'react-native';
import style from './style';
import * as WebBrowser from 'expo-web-browser';
import api from '../config/api_urls';
import Menu from '../menu';
import AsyncStorage from '@react-native-community/async-storage';

export default class Joc3 extends React.Component{

    constructor(props) {
        super(props);
        this.state = props.route.params;
        this.state['navigation'] = this.props.navigation;
        this.pages = (typeof this.state.projecte !== 'undefined' && typeof this.state.projecte.pagines !== 'undefined') ? this.state.projecte.pagines : [];
        this.state["selectedPage"] = {}
     //   console.log(this.pages)
    }

    componentDidMount(){
      //Serveix per redirecionar al menu o als creditsInici
     AsyncStorage.setItem('anarCredits',true);
  }

    _handlePressButtonAsync = async (link) => {
      let result = await WebBrowser.openBrowserAsync(link);
  //    console.log(result);
    };

plataforma(){
  return(  this.state.projecte.vistes.map((data, i) => {
      return (
      <View style={this.state.projecte.vistes.length -1 === i ? {paddingBottom:50}:{paddingBottom:10}}>
        <Text style={[style.titol,{color: this.state.projecte.ColorCorporatiuText2}]}>{data["Titol"+this.state.idioma]}</Text>
        <TouchableOpacity onPress={()=>{this._handlePressButtonAsync(data.URL)}}>
          <View style={{textAlign: 'center'}}>
            <Image style={{ marginTop: this.state.pop_up_margin_top /7, maxHeight: this.state.pop_up_height, paddingBottom: Dimensions.get('window').width * 0.30 , marginHorizontal:'2%'  }} source={{ uri: api.api_url+ data.miniatura.url }} />
            {data.Tipus == 'Vista360' ? (<Image style={{ width: 50, height: 50, position: 'absolute', zIndex: 9999, overflow: 'visible', top: '40%', left: '40%', justifyContent: 'center', alignItems: 'center' }} source={require('../../img/360.png')} />) : null}
            {data.Tipus == 'Dron' ? (<Image style={{ width: 50, height: 50, position: 'absolute', zIndex: 9999, overflow: 'visible', top: '40%', left: '40%', justifyContent: 'center', alignItems: 'center' }} source={require('../../img/dron.png')} />) : null}
          </View>
        </TouchableOpacity>
      </View>)
    }))
  }

render(){
    return(
      <View style={style.container}>
        
        <ImageBackground source={ this.state.projecte.FonsPantalla5 ? { uri: api.api_url + this.state.projecte.FonsPantalla5.url } : null }style={style.imageBackground}>
          <View style={{marginTop: this.state.pop_up_margin_top , maxHeight: this.state.pop_up_height,paddingBottom: Dimensions.get('screen').width * 0.05}}>
              <ScrollView>
              { this.plataforma() }
              </ScrollView>
          </View>
          </ImageBackground>
        
          <View style={{ flex: 2, bottom:0,left:0,right:0, position:'fixed', }}>
              <Menu projecte={this.state}></Menu>
          </View>
      </View>
    )
  }
}
import {
  StyleSheet
} from 'react-native';
import {
  Dimensions
} from "react-native";

export default StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%'
  },

  imageBackground: {
    flex: 1,
    justifyContent: "center",
  },

  text: {
    color: "black",
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
  },
  titol: {
    fontFamily: 'Lobster',
    fontSize: 21,
    textAlign: "center",
    paddingTop: 0,
    paddingBottom: 10,
    fontSize: 36,
  },
  nom: {
    // color: "white",
    textAlign: "center",
    paddingTop: 9,
    paddingBottom: 15,
    //  color: "white",
    fontWeight: 600,
    fontSize: 18,
    fontFamily: 'OpenSans',
  },
  puntsText: {
    // color: "white",
    textAlign: "center",
    //   color: "black",
    fontWeight: 700,
    fontSize: 17,
    alignSelf: 'center',
    fontFamily: 'OpenSans'
  },
  text1: {
    // color: "white",
    fontSize: 20,
    textAlign: "center",
    color: "white",
    lineHeight: 1,
    fontFamily: 'OpenSans'
  },
  text2: {
    fontSize: 60,
    fontWeight: "bold",
    textAlign: "center",
    // color: "white",
    paddingTop: 0,
    paddingBottom: 5,
    fontFamily: 'OpenSans'
  },
  icona: {
    width: 50,
    height: 50,
    //  marginLeft: 15, 
    marginBottom: -15,
    resizeMode: "stretch"
  },
  noAconseguit: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    width: 75,
    height: 75,
    paddingVertical: 10,
    paddingHorizontal: 20,
    resizeMode: 'contain',
  },
  aconseguit: {
    flexDirection: 'row',
    height: 75,
    width:75,
    paddingVertical: 20,
    paddingHorizontal: 20,
    resizeMode: 'contain',
  },
  imatgeClick: {
    width: 200,
    height: 200,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  caixaOut: {
    marginHorizontal: '10%',
    borderRadius: 10,
    flex: 1
  },
  caixa: {
    flex: 1,
    marginHorizontal: 10,
    marginVertical: 10,
  },
  caixaBorderContenidor: {
    borderWidth: 3,
    borderRadius: 10,
  },
  modalContent: {
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.1)",
    justifyContent: 'center',
    alignSelf: 'center',
    backgroundColor: 'red',
  },
  header: {
    color: 'white',
    backgroundColor: '#333',
    textAlign: 'center',
    position: 'relative',
    height: '50',
  },
  textImatge:{
     fontSize:16 ,
     alignSelf:'center',
     fontWeight:'bold',
    // color:'white',
     paddingTop:10 },


////////////////////ESTARNIN//////////////////

caixetiEstarnin:{
  paddingHorizontal:1,
  paddingTop:5,
  paddingBottom:15,
  borderRadius:10,
  marginHorizontal:5
},
imatgesEstarnin:{
  backgroundColor: 'white',
  width: 65,
  height: 65,
  paddingHorizontal: 20,
  borderRadius:10
},
imatgesEstarniNoAconseguit:{
  backgroundColor: 'rgba(255, 255,255, 0.7)',
  width: 65,
  height: 65,
  paddingHorizontal: 20,
  borderRadius:10,
  opacity:0.8
},
rodonaPunts:{ 
  borderRadius:300,
  paddingHorizontal:10,
  paddingVertical:10,
  backgroundColor:'red',
  color: "white",
},

});

/*
  width: Dimensions.get('screen').width /5.6 ,
  height: Dimensions.get('screen').width /6.5 ,
  */